import CookieModal from '@components/CookieModal';
import BottomButtonBlock from '@components/Footer/BottomButtonBlock';
import FooterMenu from '@components/Footer/FooterMenu';
import GameProviders from '@components/Footer/GameProviders';
import PaymentMethods from '@components/Footer/PaymentMethods';
import ScrollTopButton from '@components/ScrollTopButton';
import age_warning_icon from '@images/icons/age-warning-icon.svg';
import curacao from '@images/icons/curacao.svg';
import phone_icon from '@images/icons/phone-icon.svg';
import telegram_icon from '@images/icons/telegram-icon.svg';
import CustomLink from '@navigation/CustomLink';
import {locale} from '@res/strings/locale';
import {useStore} from '@store/configureStore';
import {observer} from 'mobx-react-lite';
import React from 'react';

const Footer = () => {
  const today = new Date();
  const {user} = useStore();

  return (
    <>
      <div className="container mx-auto max-w-full xl:max-w-[1296px] my-[40px] sm:mt-[100px] sm:mb-[45px]">
        <GameProviders />
      </div>
      <footer className="bg-stratos pt-[40px] pb-[66px]">
        <div className="container mx-auto max-w-full xl:max-w-[1296px] mt-[29px] sm:mt-[30px] md:mt-[29px] xl:mt-[35px]">
          <div className="hidden lg:flex mb-[61px] w-full">
            <FooterMenu />
            <div className="flex flex-1 justify-end items-start">
              {/*{window?.GlobalConfig?.region === regionTypes.CIS && (*/}
              {/*  <div className="flex mr-0 ">*/}
              {/*    /!*xl:mr-[50px]*!/*/}
              {/*    <a*/}
              {/*      aria-label="click"*/}
              {/*      href={locale?.CONTACT_INFO?.telegram}*/}
              {/*      target="_blank"*/}
              {/*      className="mr-[13px] border-[1px] px-[9px] py-[10px] border-deepSapphire rounded-[8px]">*/}
              {/*      <img src={telegram_icon} alt={locale.ALT?.telegramIcon} />*/}
              {/*    </a>*/}
              {/*    <div className="flex flex-col justify-center">*/}
              {/*      <p className="text-shipCove text-[13px] leading-[18px]">*/}
              {/*        {locale?.subscribe}*/}
              {/*      </p>*/}
              {/*      <a*/}
              {/*        aria-label="click"*/}
              {/*        href={locale?.CONTACT_INFO?.telegram}*/}
              {/*        target="_blank"*/}
              {/*        className="text-white text-[15px] font-bold leading-[20px] inline-block">*/}
              {/*        {locale?.telegramChanel}*/}
              {/*      </a>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*)}*/}

              {/*<div className="items-center relative max-w-[196px] w-full border-[1px] border-deepSapphire rounded-[8px] py-[3px] pr-[10px] hidden xl:flex">*/}
              {/*  <SubMenu*/}
              {/*    label={locale?.LANGUAGES?.ru}*/}
              {/*    isIconBordered={false}*/}
              {/*    isItemsAbsolute*/}
              {/*    icon="/images/icons/ru-lang-icon.svg"*/}
              {/*    iconAlt={locale.ALT?.ruLangIcon}*/}
              {/*    className="uppercase font-bold">*/}
              {/*    <a*/}
              {/*      aria-label="click"*/}
              {/*      href="#"*/}
              {/*      className="block px-4 py-2 text-[14px] leading-[19px]">*/}
              {/*      Link*/}
              {/*    </a>*/}
              {/*  </SubMenu>*/}
              {/*</div>*/}
            </div>
          </div>

          <div className="flex flex-col xl:flex-row justify-between w-full pb-[44px]  border-b-[1px] border-deepSapphire">
            <div className="flex justify-center lg:justify-between xl:justify-start">
              {/*<div className="relative items-center max-w-[196px] w-full border-[1px] border-deepSapphire rounded-[8px] py-[3px] pr-[10px] hidden lg:flex xl:hidden">*/}
              {/*  <SubMenu*/}
              {/*    label={locale?.LANGUAGES?.ru}*/}
              {/*    isIconBordered={false}*/}
              {/*    isItemsAbsolute*/}
              {/*    icon="/images/icons/ru-lang-icon.svg"*/}
              {/*    iconAlt={locale.ALT?.ruLangIcon}*/}
              {/*    className="uppercase font-bold">*/}
              {/*    <a*/}
              {/*      aria-label="click"*/}
              {/*      href="#"*/}
              {/*      className="block px-4 py-2 text-[14px] leading-[19px]">*/}
              {/*      Link*/}
              {/*    </a>*/}
              {/*  </SubMenu>*/}
              {/*</div>*/}
              <div className="flex">
                <div className="flex items-center border-r-[1px] border-deepSapphire pr-[29px]">
                  <img
                    src={phone_icon}
                    alt={locale.ALT?.phoneIcon}
                    className="mr-[8px]"
                  />
                  <CustomLink
                    aria-label="click"
                    to={`tel:${locale.CONTACT_INFO?.phone.replace(/\s+/g, '')}`}
                    className="text-white text-[12px] md:text-[17px] leading-[16px] md:leading-[23px]">
                    {locale.CONTACT_INFO?.phone}
                  </CustomLink>
                </div>
                <div className="flex items-center ml-[29px]">
                  <CustomLink
                    aria-label="click"
                    to={`mailto:${locale.CONTACT_INFO?.email}`}
                    className="text-white text-[12px] md:text-[17px] leading-[16px] md:leading-[23px]">
                    {locale.CONTACT_INFO?.email}
                  </CustomLink>
                </div>
              </div>
            </div>
            <div className="inline-flex flex-wrap gap-x-[15px] lg:gap-x-[30px] gap-y-[30px] lg:gap-y-[0px] justify-center  items-center mt-[42px] xl:mt-[0]">
              <PaymentMethods />
            </div>
          </div>
          <div className="flex flex-col md:flex-row mt-[40px] mb-[53px] items-center">
            {window?.GlobalConfig?.license ? (
              <iframe
                src={window?.GlobalConfig?.license}
                width={150}
                height={53}
                className={'border-none'}
              />
            ) : (
              <img
                src={curacao}
                className="mr-0 md:mr-[50px] mb-[20px] md:mb-0"
                alt={locale.ALT?.curacao}
              />
            )}

            <p className="text-chambray text-[14px] leading-[18px] max-w-[1100px] w-full">
              {locale?.copyRightText}
            </p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-chambray text-[13px] leading-[18px]">
              {`© 2015-${today.getFullYear()} ${locale?.copyRight}. 
              ${import.meta.env.VITE_VERSION ?? ''}`}
            </p>
            <img src={age_warning_icon} alt={locale.ALT?.ageWarningIcon} />
          </div>
        </div>
      </footer>
      <BottomButtonBlock authorized={user?.authorized} signIn={user.signIn} />
      <ScrollTopButton />
      <CookieModal />
    </>
  );
};

export default observer(Footer);
